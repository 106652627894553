import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useApi } from '../../query/GenericQuery';
import { Severity, VulnerabilityStatus } from '../vulnerabilities/Types';
import { searchQueryKeys } from '../vulnerabilities/VulnerabilityFilter';

interface IVulnerabilitySummary {
  severityCount:Record<Severity, number>,
  score:Severity
}

const SeverityLink = ({
  severity,
  summary,
}:{
  severity: Severity[]|Severity,
  summary: IVulnerabilitySummary,
}) => {
  const { severityCount } = summary;

  const severities = Array.isArray(severity) ? severity : [severity];

  const count = Object.keys(severityCount).reduce((prev, curr) => {
    const currSeverity = curr as Severity;
    if (severities.includes(currSeverity)) {
      return prev + severityCount[currSeverity];
    }
    return prev;
  }, 0);

  return (
    <div>
      { count > 0
        ? (
          <Link
            to={`/vulnerabilities?${severities.map((s) => `${searchQueryKeys.relativeSeverity}=${s}`).join('&')}&${searchQueryKeys.status}=${VulnerabilityStatus.Open}`}
          >
            {count}
          </Link>
        )
        : count }
    </div>
  );
};

export const VulnerabilitySummaryTile = () => {
  const { data: summary } = useApi<IVulnerabilitySummary>('vulnerabilities/summary');

  return summary
    ? (
      <Row>
        <Col xs={12} className="high col">
          <div className="severity severity-high-lighter severity-high-lighter-text">
            <h3>High</h3>
            <SeverityLink severity={[Severity.High, Severity.VeryHigh]} summary={summary} />
          </div>
        </Col>
        <Col xs={6} className="medium col">
          <div className="severity severity-medium-lighter severity-medium-lighter-text">
            <h3>Medium</h3>
            <SeverityLink severity={[Severity.Medium]} summary={summary} />
          </div>
        </Col>
        <Col xs={6} className="low col">
          <div className="severity severity-low-lighter severity-low-lighter-text">
            <h3>Low</h3>
            <SeverityLink severity={[Severity.VeryLow, Severity.Low]} summary={summary} />
          </div>
        </Col>
      </Row>
    )
    : <Spinner animation="border" />;
};
