import React from 'react';
import { Button } from 'react-bootstrap';
import { copyClipboard } from '../utils/Utils';

const getAsFormattedJsonOrString = (value:object|string|undefined) : string|undefined => {
  if (!value) return undefined;
  if (typeof value === 'string') {
    try {
      return JSON.stringify(JSON.parse(value), null, 2);
    } catch {
      return String(value);
    }
  }
  if (typeof value === 'object') return JSON.stringify(value, null, 2);
  return value;
};

export const CodeBox = ({ text, hideCopyClipboard }:{text:string, hideCopyClipboard?:boolean}) => (
  <div className="w-100 code-box light">
    <pre className="pre-wrap">
      {getAsFormattedJsonOrString(text)}
      { hideCopyClipboard !== true
        ? (
          <Button
            variant="text"
            title="Copy to clipboard"
            onClick={() => copyClipboard(
              text,
              'Copied output to clipboard',
            )}
          >
            Copy to clipboard
          </Button>
        ) : null }
    </pre>
  </div>
);
