/* eslint-disable no-await-in-loop */
import React, {
  useState, useCallback, ReactElement, useRef, useEffect,
} from 'react';
import {
  Modal, Button, Spinner,
} from 'react-bootstrap';

export interface IStep {
  title: string,
  buttonText: string,
  body: ReactElement|string,
  activeBody?: ReactElement|string,
  activeTitle?: ReactElement|string,
  fn: (() => Promise<void>)|(() => void)
}

export interface ModalProps {
  /**
   * Flag indicating whether the wizard modal should be visible.
   */
  show: boolean,
  /**
   * Function to call on close.
   */
  handleClose: () => void,
  /**
   * True to automatically start the first step of the wizard on mount.
   */
  start?: boolean
}

interface ModalInternalProps extends ModalProps {
  steps: IStep[],
  children?: JSX.Element | (JSX.Element | null)[] | undefined,
}

const WizardModal = (props: ModalInternalProps) => {
  const {
    show, steps, handleClose, children, start,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [executing, setExecuting] = useState(false);
  const hasAutostarted = useRef(false);

  const close = () => {
    setActiveStep(0);
    setExecuting(false);
    hasAutostarted.current = false;
    handleClose();
  };

  const triggerStep = useCallback(
    async () => {
      if (executing) {
        throw new Error(`Step ${activeStep} is already executing`);
      }
      setExecuting(true);
      try {
        await steps[activeStep].fn();
        if (activeStep + 1 < steps.length) {
          setActiveStep(activeStep + 1);
        }
      } catch {
      // Error message will be popped by WithAxios as long as ClientErrorException.Soft is not true
      }
      setExecuting(false);
    },
    [executing, activeStep, steps],
  );

  useEffect(() => {
    // This is the only way I've found that allows us to trigger start once, both in dev and prod.
    // Using state instead of ref results in two triggers. Seems a bit wrong, but it works.
    if (show && start && !hasAutostarted.current) {
      triggerStep();
      hasAutostarted.current = true;
    }
  }, [show, start, hasAutostarted, triggerStep]);

  return (
    <Modal
      show={show}
      onHide={close}
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeVariant="">
        <Modal.Title>
          { executing ? steps[activeStep].activeTitle ?? steps[activeStep].title : steps[activeStep].title }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { children }

        <div>
          { executing
            ? steps[activeStep].activeBody ?? steps[activeStep].body
            : steps[activeStep].body }
        </div>
      </Modal.Body>
      <Modal.Footer>
        { activeStep + 1 >= steps.length ? null : (
          <Button variant="secondary" onClick={close} disabled={executing}>
            Cancel
          </Button>
        )}
        <Button variant="primary" onClick={triggerStep} disabled={executing}>
          {steps[activeStep].buttonText}
          {!executing
            ? null
            : (
              <>
                {' '}
                <Spinner animation="border" size="sm" />
              </>
            ) }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WizardModal;
