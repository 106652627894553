/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Alert, Badge, Button, Col, Form, OverlayTrigger, Row, Spinner, Stack, Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Icon } from '@ailibs/feather-react-ts';
import VulnerabilityStatusBadge from './VulnerabilityStatusBadge';
import { RenderContentSegment } from './RenderContentSegment';
import { IContentSegment, IVulnerability } from './Types';
import { useAccount } from '../../providers/AccountProvider';
import { IUser, Module } from '../../types/AccessTypes';
import ROUTES, { Routes } from '../../routing/Routes';
import { EntityType } from '../../types/EntityTypes';
import { searchQueryKeys } from './VulnerabilityFilter';
import { useModules } from '../../providers/ModuleProvider';
import { useInvalidateQueries } from '../../query/GenericQuery';
import { SelectAssigneeModal } from './SelectAssigneeModal';
import { IModalBodyAndFooterProps, useNewModalContext } from '../../providers/NewModalProvider';
import { useAddEntityComment } from '../../components/EntityCommentsCard';
import { asNameUpn, getCveUri } from '../../utils/StringUtils';
import { getActiveStatusCssClass } from '../../utils/Utils';
import { VulnerabilityCardFooter } from './VulnerabilityCardFooter';
import RenderHtml from '../../components/RenderHtml';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { useGetSeverityAsText, useGetSignificanceAsText } from '../../utils/TranslationUtils';
import { severityAsCssClassName, severityAsLighterCssClassName } from './Utils';

export const VulnerabilityDetailsTab = ({
  vulnerability,
  details,
}:{
  vulnerability:IVulnerability,
  details:IContentSegment|undefined
}) => {
  const { user, hasModuleRole } = useAccount();
  const { getModuleNameOrDefault } = useModules();
  const { pushModal } = useNewModalContext();
  const [busy, setBusy] = useState(false);
  const [isAssignedAssignable, setIsAssignedAssignable] = useState(true);
  const invalidateVulnerability = useInvalidateQueries(`vulnerabilities/${vulnerability.id}`);
  const severityAsText = useGetSeverityAsText();

  const addEntityComment = useAddEntityComment({ entityType: EntityType.Vulnerability, entityId: vulnerability.id });

  const searchParams:Record<string, string> = {};
  searchParams[searchQueryKeys.sourceModuleId] = `${vulnerability.sourceModuleId}`;

  const assignTo = async (accountId:string|undefined) => {
    try {
      await axios.post(`/api/v1/vulnerabilities/${vulnerability.id}/${accountId ? `assignTo/${accountId}` : 'unassign'}`);
      await invalidateVulnerability();
    } catch (err) {
      toast.error(`Unable to assign vulnerability: ${err}`);
    }
  };

  useEffect(() => {
    if (!vulnerability.assignedTo?.id) return;
    axios.get<boolean>(
      `/api/v1/vulnerabilities/isAssignableAccount/${vulnerability.assignedTo.id}`,
    )
      .then(({ data: isAssignable }) => setIsAssignedAssignable(isAssignable));
  }, [vulnerability.assignedTo?.id]);

  const openAssignToModal = async () => {
    const selection = await pushModal({
      title: 'Assign vulnerability',
      size: 'sm',
      ModalBodyAndFooter: ({ close, setValue, value }:IModalBodyAndFooterProps) => (
        <SelectAssigneeModal
          close={close}
          setValue={setValue}
          value={value}
          assignedToId={vulnerability.assignedTo?.id}
        />
      ),
    }) as { selectedAssignee:IUser|undefined, comment:string };

    setBusy(true);
    try {
      if (selection || selection === null) {
        const { selectedAssignee, comment } = selection;
        if (selectedAssignee && comment.length) {
          await addEntityComment({
            entityId: `${vulnerability.id}`,
            entityType: EntityType.Vulnerability,
            comment: `*Assigned to ${asNameUpn(selectedAssignee, true)}:*<br/>${comment}`,
          });
        }
        await assignTo(selectedAssignee?.id);
      }
    } finally {
      setBusy(false);
    }
  };
  const significanceAsText = useGetSignificanceAsText();

  return (
    <>
      <Row>
        <Col md={12} className="assignee-bar">
          <Alert variant="secondary" className="px-3 py-2">
            <div className="w-100">
              <span className="assignee">
                Assignee:
                {' '}
                { busy
                  ? <Spinner animation="border" size="sm" />
                  : vulnerability.assignedTo
                    ? (
                      <>
                        { isAssignedAssignable
                          ? (
                            <span className={getActiveStatusCssClass(vulnerability.assignedTo.status, 'fw-bold')}>
                              { asNameUpn(vulnerability.assignedTo, false) }
                            </span>
                          )
                          : (
                            <OverlayTrigger
                              placement="auto"
                              overlay={(
                                <Tooltip id="search-range-tooltip">
                                  The user is assigned but no longer has permission to view the vulnerability.
                                </Tooltip>
                              )}
                            >
                              <span className={`fw-bold ${isAssignedAssignable ? '' : 'text-decoration-line-through'}`}>
                                { vulnerability.assignedTo?.name ?? vulnerability.assignedTo.externalId }
                              </span>
                            </OverlayTrigger>
                          ) }
                        <Button
                          size="sm"
                          variant="link"
                          disabled={busy}
                          className="p-0 mx-1"
                          title="Unassign"
                          onClick={async () => assignTo(undefined)}
                        >
                          <Icon size="18" name="x-square" />
                        </Button>
                      </>
                    )
                    : <span>Unassigned</span>}
              </span>
              <span className="float-end assign-buttons">
                <Stack direction="horizontal" gap={2}>
                  { vulnerability.assignedTo?.id !== user.id
                    ? (
                      <Button
                        variant="link"
                        size="sm"
                        disabled={busy}
                        title="Assign to me"
                        onClick={async () => assignTo(user.id)}
                      >
                        Assign to me
                      </Button>
                    )
                    : null }
                  { vulnerability.assignedTo?.id === user.id || hasModuleRole(Module.vulnerability, 'readWrite')
                    ? (
                      <Button
                        size="sm"
                        disabled={busy}
                        title="Assign to..."
                        onClick={async () => openAssignToModal()}
                      >
                        Assign to...
                      </Button>
                    )
                    : null }

                </Stack>
              </span>
            </div>
          </Alert>
        </Col>
        <Col xs={9} lg={10} className="mb-3">
          <Form.Label>Summary:</Form.Label>
          <div>
            {vulnerability.summary
              ? (
                <RenderHtml allowedTags={['a', 'br']} allowedAttributes={['target']}>
                  {vulnerability.summary}
                </RenderHtml>
              ) : null}
          </div>
        </Col>
        <Col xs={3} lg={2} className="mb-3">
          <Row>
            <Col md={12}>
              <div className="float-end">
                <Form.Label>Status:</Form.Label>
                <div>
                  <VulnerabilityStatusBadge
                    vulnerability={vulnerability}
                    style={{
                      minWidth: '60px',
                      textAlign: 'left',
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-3 vulnerability-details">
          { vulnerability.status === 'open'
            ? (
              <Row>
                <Col md={6}>
                  <Alert variant="light" className={`p-3 ${severityAsLighterCssClassName(vulnerability.relativeSeverity, false)} text-dark`}>
                    <div>
                      <Form.Label className="me-1">Criticality:</Form.Label>
                      <Badge bg="none" className={severityAsCssClassName(vulnerability.relativeSeverity)}>
                        {severityAsText(vulnerability.relativeSeverity)}
                      </Badge>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        overlay={(
                          <Tooltip>
                            <div className="text-start">
                              <Form.Label>Probability:</Form.Label>
                              <span className="ms-1">
                                {significanceAsText(vulnerability.probability)}
                              </span>
                            </div>
                            <div className="text-start">
                              <Form.Label>Impact:</Form.Label>
                              <span className="ms-1">
                                {significanceAsText(vulnerability.probability)}
                              </span>
                            </div>
                            <div className="text-start">
                              <Form.Label>Asset significance:</Form.Label>
                              <span className="ms-1">
                                {significanceAsText(
                                  vulnerability.asset.significance ?? vulnerability.asset.systemSignificance,
                                )}
                              </span>
                            </div>
                            <div className="text-start">
                              <Form.Label>Relative impact:</Form.Label>
                              <span className="ms-1">
                                {significanceAsText(vulnerability.probability)}
                              </span>
                            </div>
                          </Tooltip>
                        )}
                      >
                        <Button className="ms-2" variant="text">
                          <Icon name="info" />
                        </Button>
                      </OverlayTrigger>
                      <div>
                        Criticality reflects the relative impact of a vulnerability across the organization,
                        guiding the order in which issues should be addressed based on their overall risk.
                      </div>
                    </div>
                  </Alert>
                </Col>
                <Col md={6}>
                  <Alert variant="light" className={`p-3 ${severityAsLighterCssClassName(vulnerability.severity, false)} text-dark`}>
                    <div>
                      <Form.Label className="me-1">Severity:</Form.Label>
                      <Badge bg="none" className={severityAsCssClassName(vulnerability.severity)}>
                        {severityAsText(vulnerability.severity)}
                      </Badge>
                      <div>
                        Severity represents the inherent impact of a vulnerability on a single asset, indicating its
                        potential risk level independent of other factors.
                      </div>
                    </div>
                  </Alert>
                </Col>
              </Row>
            )
            : null }
          { details?.contents.length
            ? (
              <>
                <Form.Label>Details:</Form.Label>
                <RenderContentSegment
                  id={`vulnerability-${vulnerability.id}-content-default`}
                  content={details.contents[0]}
                />
              </>
            )
            : null }
        </Col>
      </Row>
      { vulnerability.cves?.filter((cve) => cve.notes?.length).map((cve) => (
        <Row key={cve.id}>
          <Col key={cve.id} md={6} className="mb-3">
            <Alert variant="info" className="p-3">
              <div>
                <strong>{ cve.id }</strong>
                :
                <RenderHtml className="mt-1">{ cve.notes }</RenderHtml>
                { hasModuleRole(Module.admin, 'readWrite')
                  ? (
                    <div className="mt-2">
                      <Link
                        to={`/admin/cves?id=${cve.id}`}
                      >
                        <Icon name="edit" className="me-2" size="18" />
                        Edit
                      </Link>
                    </div>
                  ) : null }
              </div>
            </Alert>
          </Col>
        </Row>
      ))}
      { vulnerability.cves?.length
        ? (
          <Row>
            <Col md={12} className="mb-3">
              <Form.Label>
                Associated to CVE
                {vulnerability.cves.length > 1 ? 's' : ''}
                :
              </Form.Label>
              <ul className="mb-0">
                {vulnerability.cves.map((cve) => (
                  <li key={cve.id}>
                    <ClipboardCopy size="18">
                      {cve.id}
                    </ClipboardCopy>
                    <OverlayTrigger
                      overlay={<Tooltip>Open CVE details</Tooltip>}
                    >
                      <Link
                        to={getCveUri(cve.id)}
                        target="_blank"
                      >
                        <Icon name="external-link" className="ms-2" size="18" />
                      </Link>
                    </OverlayTrigger>
                    { hasModuleRole(Module.admin, 'readWrite')
                      ? (
                        <OverlayTrigger
                          overlay={<Tooltip>Edit CVE notes</Tooltip>}
                        >
                          <Link
                            to={`/admin/cves?id=${cve.id}`}
                          >
                            <Icon name="edit" className="ms-2" size="18" />
                          </Link>
                        </OverlayTrigger>
                      ) : null }
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        ) : null }
      <Row>
        <Col md={12}>
          <Form.Label>Relations:</Form.Label>
          <ul className="mb-0">
            <li>
              Provider:
              {' '}
              { hasModuleRole(Module.vulnerability, 'read')
                ? (
                  <Link
                    title={getModuleNameOrDefault(vulnerability.sourceModuleId)}
                    to={Routes.getRouteRelativeUri(ROUTES.vulnerabilities, searchParams, true).toString()}
                  >
                    {getModuleNameOrDefault(vulnerability.sourceModuleId)}
                  </Link>
                )
                : getModuleNameOrDefault(vulnerability.sourceModuleId) }
            </li>
            <li>
              Asset:
              {' '}
              { hasModuleRole(Module.assets, 'read')
                ? (
                  <Link
                    title={vulnerability.asset.name}
                    to={`${ROUTES.assets.uri}/${vulnerability.asset.id}`}
                  >
                    {vulnerability.asset.name}
                  </Link>
                ) : vulnerability.asset.name }
            </li>
            <li>
              Control:
              {' '}
              { hasModuleRole(Module.vulnerability, 'read')
                ? (
                  <Link
                    title={vulnerability.control.name}
                    to={`${ROUTES.control.uri}/${vulnerability.control.id}`}
                  >
                    {vulnerability.control.name}
                  </Link>
                )
                : vulnerability.control.name }
            </li>
            { vulnerability.risk
              ? (
                <li>
                  Risk:
                  {' '}
                  { hasModuleRole(Module.vulnerability, 'read')
                    ? (
                      <Link
                        title={vulnerability.control.name}
                        to={`${ROUTES.risk.uri}/${vulnerability.risk?.riskId}`}
                      >
                        {vulnerability.risk?.name}
                      </Link>
                    )
                    : vulnerability.risk?.name }
                </li>
              ) : null }
          </ul>
        </Col>
      </Row>
      <VulnerabilityCardFooter vulnerability={vulnerability} />
    </>
  );
};
