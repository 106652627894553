import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Card, Col, Row, Spinner, Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { startAutomationJobAndAwaitResult } from '../../utils/AutomationJobRunner';
import { IJob } from '../../types/Types';
import { Module } from '../../types/AccessTypes';

const startControlActionUpdate = async () => (await axios.post<IJob>(
  '/api/v1/module/vulnerabilityJobs/start',
  {
    moduleId: Module.m365,
    lookupKey: 'get-control-actions',
  },
)).data;

export const AdminToolsPage = () => {
  const [updatingControlActions, setUpdatingControlActions] = useState(false);
  const abortControllerRef = useRef<AbortController>();

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    return (() => {
      abortControllerRef.current?.abort();
    });
  }, []);

  const updateControlActions = async () => {
    setUpdatingControlActions(true);
    try {
      await startAutomationJobAndAwaitResult(startControlActionUpdate, abortControllerRef.current?.signal);
      toast.info('Microsoft 365 control actions are updated', {
        toastId: 'm365-control-action-update',
        type: 'success',
        autoClose: 5000,
      });
    } catch {
      // No need to do anything
    } finally {
      setUpdatingControlActions(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          Tools
        </Card.Title>
      </Card.Header>
      <Card.Body className="overflow-auto">
        <Row>
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              <Button onClick={updateControlActions} disabled={updatingControlActions}>
                Update M365 control actions
                { updatingControlActions
                  ? (
                    <>
                      {' '}
                      <Spinner animation="border" size="sm" />
                    </>
                  )
                  : ''}
              </Button>
            </Stack>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AdminToolsPage;
