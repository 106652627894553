import {
  useCallback, useEffect,
} from 'react';
import {
  useBeforeUnload, useLocation, useNavigation,
} from 'react-router-dom';
import { useAccount } from '../providers/AccountProvider';

const scrollPrefix = 'scroll-restoration-';

interface IPosition {
  left: number,
  top: number
}

const getPositionsOrDefault = (storageKey:string) => {
  const positionsJson = sessionStorage.getItem(storageKey);

  if (positionsJson) {
    try {
      return JSON.parse(positionsJson) as IPosition;
    } catch {
      // Remove invalid storage key
      sessionStorage.removeItem(storageKey);
    }
  }

  return {
    top: 0,
    left: 0,
  };
};

export const ElementScrollRestoration = (props:{targetId:string}) => {
  const { targetId } = props;

  const { customer } = useAccount();
  const navigation = useNavigation();
  const location = useLocation();

  const storageKey = `${scrollPrefix}${customer?.id}-${location.pathname}-${targetId}`;

  const saveScrollPosition = useCallback(() => {
    const targetElement = document.getElementById(targetId);
    if (!targetElement || navigation.state !== 'idle') {
      return;
    }

    const positions:IPosition = {
      left: targetElement.scrollLeft,
      top: targetElement.scrollTop,
    };

    sessionStorage.setItem(storageKey, JSON.stringify(positions));
  }, [navigation.state, storageKey, targetId]);

  useEffect(() => {
    const targetElement = document.getElementById(targetId);
    if (!targetElement || !storageKey) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    const positions = getPositionsOrDefault(storageKey);

    targetElement.scrollTop = positions.top;
    targetElement.scrollLeft = positions.left;

    targetElement.addEventListener('scroll', saveScrollPosition);

    return () => {
      targetElement.removeEventListener('scroll', saveScrollPosition);
    };
  }, [location, saveScrollPosition, storageKey, targetId]);

  useEffect(() => {
    const targetElement = document.getElementById(targetId);
    if (!targetElement) {
      return;
    }
    saveScrollPosition();
  }, [location, navigation.state, saveScrollPosition, targetId]);

  useBeforeUnload(() => {
    saveScrollPosition();
  });

  return '';
};
