import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { createAlgizRouter, routerFutures } from './Router';
import { useAccount } from '../providers/AccountProvider';
import { ValidPageSizes } from '../common/table/PaginationV8';
import { useDefaultTableStoreV8 } from '../common/table/TableStoreV8';
import { useActivateCustomerFromQuery } from './useActivateCustomerFromQuery';

export const AuthenticatedApp = () => {
  const queryClient = useQueryClient();
  const account = useAccount();

  const { store: tableStore } = useDefaultTableStoreV8();
  const paginationState = tableStore.getState().pagination;

  const router = createAlgizRouter(
    queryClient,
    account,
    paginationState.pageSize as ValidPageSizes,
  );

  useActivateCustomerFromQuery();

  return (
    <RouterProvider
      router={router}
      future={routerFutures}
    />
  );
};
