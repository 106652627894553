import React from 'react';
import { IVulnerability } from './Types';
import { EntityType } from '../../types/EntityTypes';
import ROUTES from '../../routing/Routes';
import { EntityCardFooter } from '../../components/EntityCardFooter';
import { ClipboardCopy } from '../../components/ClipboardCopy';

export const VulnerabilityCardFooter = ({ vulnerability }:{vulnerability:IVulnerability}) => (
  <EntityCardFooter
    timestamps={vulnerability}
    entity={{ entityId: vulnerability.id, entityType: EntityType.Vulnerability }}
    linkRoute={ROUTES.vulnerabilities}
    visibleId={`${vulnerability.id}`}
    additionalDetails={(
      <span>
        <span>
          Id:
          <ClipboardCopy className="ms-1 me-2">
            {vulnerability.id}
          </ClipboardCopy>
        </span>
        { vulnerability.uniqueId
          ? (
            <span>
              Unique Id:
              <ClipboardCopy className="ms-1 me-2">
                {vulnerability.uniqueId}
              </ClipboardCopy>
            </span>
          ) : null }
      </span>
    )}
  />
);
