import React, { useState } from 'react';
import {
  Button, Card, Col, Row, Tab, Tabs,
} from 'react-bootstrap';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { Icon } from '@ailibs/feather-react-ts';
import CustomerSelector from './CustomerSelector';
import { AdminCustomer, ICustomerEssentials } from '../../types/AdminTypes';
import { AdminCustomerAccountTab } from './AdminCustomerAccountTab';
import AdminCustomerDetailsTab from './AdminCustomerDetailsTab';
import AdminCustomerModulesTab from './AdminCustomerModulesTab';
import { getOrFetchFromApi, useApi, useApiLoaderData } from '../../query/GenericQuery';
import AdminCustomerGroupsTab from './AdminCustomerGroupsTab';
import AddEditAdminModal from './AddEditAdminModal';
import AdminCustomerAddForm from './AdminCustomerAddForm';
import { EntityChangelogButton } from '../../components/EntityChangelogModal';
import { EntityType } from '../../types/EntityTypes';
import { Module } from '../../types/AccessTypes';
import { AdminCustomerAssociationsTab } from './AdminCustomerAssociationsTab';
import { IAccountDetails, useAccount } from '../../providers/AccountProvider';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { AdminCustomerSettingsTab } from './AdminCustomerSettingsTab';
import { useDownloadFromApi } from '../../common/useDownloadFromApi';
import { EntityCardFooter } from '../../components/EntityCardFooter';
import { ClipboardCopy } from '../../components/ClipboardCopy';

interface ICustomerStats {
  customerCount: number,
  accountCount: number
}

interface IData {
  customer:AdminCustomer|null,
  stats: ICustomerStats
}

export const AdminCustomersPage:IComponentWithLoader<IData, {id?:string}> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:number,
    args:{id?:string}|undefined,
  ) => (
    {
      customer: args?.id
        ? await getOrFetchFromApi<AdminCustomer>(queryClient, `module/admin/customers/${args.id}`)
        : null,
      stats: await getOrFetchFromApi<ICustomerStats>(queryClient, 'module/admin/customers/stats'),
    } as IData
  ),
  Component: () => {
    const { hasModuleRole } = useAccount();
    const [customerSearchString, setCustomerSearchString] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const activeTabKey = searchParams.get('tab') ?? 'details';
    const selectedCustomerId = searchParams.get('customer');

    const { data: selectedCustomer } = useApiLoaderData<AdminCustomer|null|undefined, IData|null>(
      selectedCustomerId && `module/admin/customers/${selectedCustomerId}`,
      (loaderData) => loaderData?.customer,
    );

    const { stats } = (useLoaderData() as Awaited<IData>);

    const downloadFromApi = useDownloadFromApi();

    const { data: selectedCustomerAssociatedTo } = useApi<ICustomerEssentials[]>(
      selectedCustomer && activeTabKey === 'associations' && `module/admin/customers/${selectedCustomer.id}/associations`,
    );

    const activateCustomer = (customer:AdminCustomer|undefined) => {
      if (customer) {
        searchParams.set('customer', customer.id);
      } else {
        searchParams.delete('customer');
      }
      setSearchParams(searchParams);
    };

    const [show, setShow] = useState<boolean>(false);
    const openAddCustomerModal = () => {
      setShow(true);
    };

    const handleClose = (addedCustomer?:AdminCustomer|undefined) => {
      setShow(false);
      activateCustomer(addedCustomer);
    };

    const setTab = (tab:string|null) => {
      if (!tab) return;
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        tab,
      });
    };

    return (
      <>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  Select customer
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={6} className="mb-3">
                    <Row>
                      <Col md={12} className="mb-3">
                        <CustomerSelector
                          selectedCustomer={selectedCustomer}
                          onCustomerSelected={activateCustomer}
                          onSearchChange={setCustomerSearchString}
                        />
                      </Col>
                      { selectedCustomer && hasModuleRole(Module.admin, 'read')
                        ? (
                          <Col md={12} className="mb-3">
                            <EntityChangelogButton
                              entityId={selectedCustomer.id}
                              entityType={EntityType.Customer}
                            />
                          </Col>
                        )
                        : (
                          <Col md={12} className="mb-3">
                            <Button onClick={openAddCustomerModal}>
                              Add customer
                            </Button>
                            <AddEditAdminModal
                              show={show}
                              isAdd
                              handleClose={handleClose}
                              itemType="customer"
                              size="lg"
                              FormElem={(
                                <AdminCustomerAddForm
                                  handleClose={handleClose}
                                  defaultName={customerSearchString}
                                />
                              )}
                            />
                          </Col>
                        )}
                    </Row>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div>
                      <strong>Active customers:</strong>
                      {' '}
                      {stats.customerCount}
                      <br />
                      <strong>Active accounts:</strong>
                      {' '}
                      {stats.accountCount}
                    </div>
                    <Button
                      variant="secondary"
                      className="mt-3"
                      onClick={() => downloadFromApi('module/admin/customers/csv')}
                    >
                      Download customer list
                      <Icon name="download" size={16} className="ms-2" />
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        { selectedCustomer ? (
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    Customer management
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Tabs
                    defaultActiveKey={activeTabKey}
                    className="mb-3"
                    mountOnEnter
                    onSelect={(t) => setTab(t)}
                  >
                    <Tab eventKey="details" title="Details">
                      <AdminCustomerDetailsTab customer={selectedCustomer} />
                      <EntityCardFooter
                        timestamps={selectedCustomer}
                        entity={{
                          entityId: selectedCustomer.id,
                          entityType: EntityType.Customer,
                        }}
                        visibleId={`${selectedCustomer.name} (id: ${selectedCustomer.id})`}
                        additionalDetails={(
                          <span>
                            Id:
                            <ClipboardCopy className="ms-1 me-2">
                              {selectedCustomer.id}
                            </ClipboardCopy>
                          </span>
                        )}
                      />
                    </Tab>
                    <Tab eventKey="modules" title="Modules">
                      <AdminCustomerModulesTab customer={selectedCustomer} />
                    </Tab>
                    <Tab eventKey="accounts" title="Accounts">
                      <AdminCustomerAccountTab customer={selectedCustomer} />
                    </Tab>
                    <Tab eventKey="groups" title="Account groups">
                      <AdminCustomerGroupsTab customer={selectedCustomer} />
                    </Tab>
                    <Tab eventKey="associations" title="Service provider">
                      <AdminCustomerAssociationsTab
                        customer={selectedCustomer}
                        associatedCustomers={selectedCustomerAssociatedTo}
                      />
                    </Tab>
                    <Tab eventKey="settings" title="Settings">
                      <AdminCustomerSettingsTab
                        customer={selectedCustomer}
                      />
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : null }
      </>
    );
  },
};

export default AdminCustomersPage;
