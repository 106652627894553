/* eslint-disable no-await-in-loop */
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  Alert, Spinner,
} from 'react-bootstrap';
import a from 'indefinite';
import {
  IVulnerability, IControlDetails, IControlImpact, ImpactSeverity, IControlConfigureResult, ExecutionStatus,
} from './Types';
import { IJob } from '../../types/Types';
import { startAutomationJobAndAwaitResult } from '../../utils/AutomationJobRunner';
import WizardModal, { IStep, ModalProps } from '../../components/WizardModal';
import RenderHtml from '../../components/RenderHtml';

interface RemediateModalProps extends ModalProps {
  vulerability: IVulnerability,
  control: IControlDetails,
  start?: boolean
}

const startImpact = async (vuln:IVulnerability, controlDetails:IControlDetails) => (await axios.post<IJob>(
    `/api/v1/module/vulnerabilityJobs/controlStart/${vuln.sourceModuleId}/execute-control-action/${vuln.asset.friendlyId}/${controlDetails?.frameworkFriendlyId}/${controlDetails?.friendlyId}/${vuln.uniqueId ? `${vuln.uniqueId}/` : '' }impact`,
)).data;

const startRemidiate = async (vuln:IVulnerability, controlDetails:IControlDetails) => (await axios.post<IJob>(
  `/api/v1/module/vulnerabilityJobs/controlStart/${vuln.sourceModuleId}/execute-control-action/${vuln.asset.friendlyId}/${controlDetails?.frameworkFriendlyId}/${controlDetails?.friendlyId}/${vuln.uniqueId ? `${vuln.uniqueId}/` : '' }configure`,
)).data;

const ControlRemediateModal = (props: RemediateModalProps) => {
  const {
    show, handleClose, vulerability: vuln, control: controlDetails, start,
  } = props;

  const [impact, setImpact] = useState<IControlImpact|undefined>();
  const [configurationResult, setConfigurationResult] = useState<IControlConfigureResult|undefined>();

  const abortControllerRef = useRef<AbortController>();

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    return (() => {
      abortControllerRef.current?.abort();
    });
  }, []);

  const close = () => {
    setImpact(undefined);
    setConfigurationResult(undefined);
    handleClose();
  };

  const steps:IStep[] = [
    {
      title: 'Preparing remediation',
      body: (
        <div>
          <p>
            To remediate this vulnerability we will update your assets configuration.
          </p>
          <p>
            Before configuring we will assess the impact of remediating the vulnerability and
            present the result before allowing you to continue or abort the process.
          </p>
        </div>
      ),
      buttonText: 'Evaluate impact',
      activeTitle: 'Evaluating, please wait...',
      activeBody: (
        <>
          <p>
            We&apos;re evaluating the impact of remediating the vulnerability.
          </p>
          <p>
            When the impact is determined, you&apos;ll be given a choice to
            {' '}
            <em>cancel</em>
            {' '}
            or
            continue
            {' '}
            <em>remediating</em>
            {' '}
            the vulnerability.
          </p>
          <p>
            Please wait...
            {' '}
            <Spinner animation="grow" size="sm" as="span" />
          </p>
        </>
      ),
      fn: async () => {
        try {
          setImpact(
            await startAutomationJobAndAwaitResult<IControlImpact>(
              () => (startImpact(vuln, controlDetails)),
              abortControllerRef.current?.signal,
            ),
          );
        } catch {
          close();
        }
      },
    },
    {
      title: 'Confirm remediation',
      buttonText: 'Remediate',
      body: (
        <div>
          { impact?.impact === ImpactSeverity.None
            ? (
              <div>
                <p>
                  <em>{vuln.details}</em>
                  {' '}
                  can be remediated with no
                  impact for your systems or users.
                </p>
                { impact?.details
                  ? (
                    <Alert variant="info" className="p-2">
                      <RenderHtml>
                        { impact?.details }
                      </RenderHtml>
                    </Alert>
                  ) : null }
                <p>
                  Please click
                  {' '}
                  <em>Remediate</em>
                  {' '}
                  to fix this vulnerability, or
                  {' '}
                  <em>Cancel</em>
                  {' '}
                  to abort.
                </p>
              </div>
            )
            : (
              <div>
                <p>
                  Remediating this vulnerability has
                  {' '}
                  {a(impact?.impact ?? 'unknown')}
                  {' '}
                  impact.
                  <br />
                  <br />
                  Please evaluate the below impact analysis before continuing to remediate the vulnerability:
                </p>
                <Alert variant="danger" className="p-2">
                  <RenderHtml>
                    { impact?.details ? impact?.details : 'No details provided!' }
                  </RenderHtml>
                </Alert>
                <p>
                  Please click
                  {' '}
                  <em>Remediate</em>
                  {' '}
                  to fix this vulnerability, or
                  {' '}
                  <em>Cancel</em>
                  {' '}
                  to abort.
                </p>
              </div>
            )}
        </div>
      ),
      activeBody: (
        <p>
          Remediating, please wait...
          {' '}
          <Spinner animation="grow" size="sm" as="span" />
        </p>
      ),
      activeTitle: 'Remediating, please wait...',
      fn: async () => {
        try {
          const result = await startAutomationJobAndAwaitResult<IControlConfigureResult>(
            () => startRemidiate(vuln, controlDetails),
            abortControllerRef.current?.signal,
          );
          setConfigurationResult(result);
        } catch {
          close();
        }
      },
    },
    {
      title: 'Vulnerability remediated',
      body: (
        <div>
          { configurationResult?.status === ExecutionStatus.Ok
            ? (
              <>
                <p>
                  <em>{vuln.details}</em>
                  {' '}
                  was successfully remediated
                  for
                  {' '}
                  <em>{vuln.asset.name ?? vuln.asset.friendlyId}</em>
                  .
                </p>
                { configurationResult && configurationResult.details ? (
                  <Alert variant="info" className="p-2">
                    <RenderHtml>
                      { configurationResult.details }
                    </RenderHtml>
                  </Alert>
                ) : null }
              </>
            )
            : (
              <Alert variant="warning">
                <RenderHtml>
                  {
                    configurationResult?.details
                      ?? 'An error occured while configuring, and the module provided no additional information.'
                  }
                </RenderHtml>
              </Alert>
            )}
        </div>
      ),
      buttonText: 'Close',
      fn: () => close(),
    },
  ];

  return (
    <WizardModal steps={steps} handleClose={handleClose} show={show} start={start} />
  );
};

export default ControlRemediateModal;
