import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useGetSeverityAsText, useVulnerabilityStatusAsText } from '../../utils/TranslationUtils';
import {
  getImplementationPercentText, vulnerabilityStatusAndSeverityAsColorClassNames,
} from './Utils';
import { IStatusableVulnerability, VulnerabilityStatus } from './Types';
import { severityAsCssClassName } from '../vulnerabilities/Utils';

const VulnerabilityStatusBadge = ({
  vulnerability,
  className,
  style,
}:{
  vulnerability:IStatusableVulnerability,
  className?:string|undefined,
  style?:React.CSSProperties|undefined
}) => {
  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();
  const severityAsText = useGetSeverityAsText();

  const badgeClassNames = `${vulnerabilityStatusAndSeverityAsColorClassNames(vulnerability.status, vulnerability.relativeSeverity)}${className ? ` ${className}` : ''}`;

  return vulnerability.status === VulnerabilityStatus.Mitigated
    ? (
      <Badge
        bg="none"
        className={badgeClassNames}
      >
        { vulnerabilityStatusAsText(vulnerability.status) }
        { getImplementationPercentText(vulnerability) }
      </Badge>
    )
    : (
      <OverlayTrigger
        overlay={(
          <Tooltip>
            <div className="p-1">
              <div className="text-start mb-2">
                Criticality:
                {' '}
                <Badge
                  style={{ fontSize: '0.7rem' }}
                  bg="none"
                  pill
                  className={`text-shadow ${severityAsCssClassName(vulnerability.relativeSeverity)}`}
                >
                  {severityAsText(vulnerability.relativeSeverity)}
                </Badge>
              </div>
              <div className="text-start">
                Severity:
                {' '}
                <Badge
                  style={{ fontSize: '0.7rem' }}
                  bg="none"
                  pill
                  className={`text-shadow ${severityAsCssClassName(vulnerability.severity)}`}
                >
                  {severityAsText(vulnerability.severity)}
                </Badge>
              </div>
            </div>
          </Tooltip>
        )}
      >
        <Badge bg="none" className={badgeClassNames} style={style}>
          { vulnerabilityStatusAsText(vulnerability.status) }
          { getImplementationPercentText(vulnerability) }
        </Badge>
      </OverlayTrigger>
    );
};

export default VulnerabilityStatusBadge;
