import React, { createContext, useContext } from 'react';
import axios from 'axios';

export interface IConfig {
  APPLICATION_INSIGHTS_CONNECTION_STRING: string,
  AZURE_REDIRECT_URI: string,
  SIGNIN_PAGE_URI: string,
}

// Solution inspired by https://betweendata.io/posts/react-build-once-run-anywhere/

export const loadConfig = async () => {
  try {
    const result = await axios.get<IConfig>('/config/config.json');
    return result.data;
  } catch {
    // eslint-disable-next-line no-alert
    alert('Unable to load configuration!');
    return {} as IConfig;
  }
};

const ConfigContext = createContext<IConfig|undefined>(undefined);

export const useConfigContext = () => {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('No ConfigContext found when calling useConfigContext');
  }
  return context;
};

export const ConfigContextProvider = ({ config, children }: { config:IConfig, children: React.ReactNode }) => (
  <ConfigContext.Provider value={config}>
    { children }
  </ConfigContext.Provider>
);
