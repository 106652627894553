import React from 'react';
import { TimestampsForEntity } from './Timestamps';
import { useAccount } from '../providers/AccountProvider';
import { Module } from '../types/AccessTypes';
import { asObjectTypeName, EntityChangelogButton } from './EntityChangelogModal';
import { IHaveTimestamps } from '../types/Types';
import { IHaveEntityId } from '../types/EntityTypes';
import { CopyRouteLinkButton } from './CopyRouteLinkButton';
import { Route } from '../routing/Routes';

export const EntityCardFooter = ({
  timestamps,
  entity,
  linkRoute,
  additionalDetails,
  visibleId,
}:{
  timestamps:IHaveTimestamps,
  entity:IHaveEntityId,
  linkRoute?:Route,
  additionalDetails?:React.ReactNode|string|undefined,
  visibleId?: string
}) => {
  const { hasModuleRole } = useAccount();

  return (
    <div className="card-details-footer d-flex">
      <div className="text-truncate flex-grow-1 text">
        <TimestampsForEntity entity={timestamps} />
        { hasModuleRole(Module.admin, 'read') && additionalDetails
          ? (
            <>
              <br />
              {additionalDetails}
            </>
          )
          : null }
      </div>
      <div className="ml-auto text-nowrap text-end links">
        <EntityChangelogButton
          entityId={entity.entityId}
          entityType={entity.entityType}
          filterByObjectTypes={hasModuleRole(Module.admin, 'read') ? undefined : asObjectTypeName(entity.entityType)}
          visibleId={visibleId}
        />
        { linkRoute
          ? <CopyRouteLinkButton route={linkRoute} id={entity.entityId} name={entity.entityType} />
          : null }
      </div>
    </div>
  );
};
