import { useEffect, useState } from 'react';
import { useAccount } from '../providers/AccountProvider';
import useSettingsContext from '../contexts/SettingsContext';

const initialCustomerIdParamName = 'activateCustomer';

const getInitalCustomerIdFromUrl = () => {
  const { searchParams } = new URL(window.location.href);
  if (searchParams.has(initialCustomerIdParamName)) {
    return searchParams.get(initialCustomerIdParamName) ?? undefined;
  }
  return undefined;
};

export const useActivateCustomerFromQuery = () => {
  const account = useAccount();
  const { activeCustomerId, setActiveCustomerId } = useSettingsContext();
  const [currentCustomerId, setCurrentCustomerId] = useState(getInitalCustomerIdFromUrl());

  useEffect(() => {
    const handleUrlChange = () => {
      const customerId = getInitalCustomerIdFromUrl();
      if (customerId) {
        setCurrentCustomerId(customerId);
      }
    };
    window.addEventListener('popstate', handleUrlChange);
    window.addEventListener('hashchange', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
      window.removeEventListener('hashchange', handleUrlChange);
    };
  }, []);

  useEffect(() => {
    if (!activeCustomerId
      && currentCustomerId
      && account?.customer.id !== currentCustomerId
      && account?.canAssociate
      && account.isAssociatedTo?.map((c) => c.id).includes(currentCustomerId)
    ) {
      setActiveCustomerId(currentCustomerId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomerId, account]);
};
