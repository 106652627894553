import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { createUnauthenticatedAlgizRouter } from './UnauthenticatedRouter';
import { routerFutures } from './Router';

export const UnauthenticatedApp = () => {
  const router = createUnauthenticatedAlgizRouter();

  return (
    <RouterProvider
      router={router}
      future={routerFutures}
    />
  );
};
