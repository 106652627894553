import { IUser } from '../../types/AccessTypes';
import { IAsset } from '../../types/AssetsTypes';
import { IRisk } from '../../types/RiskTypes';

export enum VulnerabilityStatus {
  Unknown = 'unknown',
  Open = 'open',
  Mitigated = 'mitigated',
}

export enum RiskLevel
{
  Unknown = 'unknown',
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}

export type ControlAction = 'impact'|'configure'|'revert'|'analyze';

export interface IActionTarget {
  control:string,
  action:ControlAction,
  uniqueId:string|undefined,
  moduleId:number|undefined
}

export enum SecurityLevel
{
  Unknown = 'unknown',
  Basic = 'basic',
  Improved = 'improved',
  Advanced = 'advanced',
}

export interface IControl {
  id: string,
  friendlyId: string,
  sortIndex: string|undefined,
  name: string,
  description: string|undefined,
  function: string,
  functionName: string|undefined,
  functionShortName: string|undefined,
  topic: string,
  topicName: string|undefined,
  topicShortName: string|undefined,
  securityLevel: SecurityLevel,
}

export interface IControlAlias extends IControl {
  frameworkFriendlyId: string,
  frameworkVersion: string,
}

export enum Severity {
  Unknown = 'unknown',
  VeryLow = 'veryLow',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  VeryHigh = 'veryHigh',
}

export enum Significance {
  None = 'none',
  VeryLow = 'veryLow',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  VeryHigh = 'veryHigh'
}

export interface ISeverityComponents {
  probability: Significance,
  impact: Significance,
  relativeImpact?: Significance
}

export const AllSignificances = [
  Significance.VeryLow,
  Significance.Low,
  Significance.Medium,
  Significance.High,
  Significance.VeryHigh,
];

export interface IAggregatedVulnerabilityStatus {
  statuses: VulnerabilityStatus[],
  relativeSeverity?: Severity,
  severity?: Severity,
}

export interface IStatusableVulnerability extends ISeverityComponents {
  status: VulnerabilityStatus,
  mitigationPercent?: number,
  relativeSeverity?: Severity,
  severity: Severity,
}

export interface IContent {
  type:'markdown'|'table'
  body: object
}

export interface ITableContentBody {
  headers: string[],
  rows: (string|undefined|null)[][],
}

export interface IContentSegment {
  header:string|undefined,
  contents:IContent[],
}

export interface ICve {
  id:string,
  notes:string
}

export interface ISecurityAdvisory {
  id:string,
  name?:string
}

export interface ISoftware {
  id:string,
  name:string,
  vendor?:string,
  version?:string,
  diskPath?:string,
  securityAdvisoryId?:string,
  securityAdvisory?:ISecurityAdvisory
}

export interface IVulnerability extends IStatusableVulnerability {
  id: number,
  summary: string|undefined,
  customerId: string,
  details: string|undefined,
  detailsSegments: IContentSegment[],
  remediation: string|undefined,
  created: Date,
  updated: Date,
  control: IControl,
  asset: IAsset,
  risk?: IRisk,
  uniqueId: string,
  sourceModuleId: number,
  assignedTo?: IUser,
  cves?: ICve[],
  cveIds?: string[],
  software?: ISoftware[],
}

export interface IControlDetails extends IControl {
  equivalents: IControlAlias[],
  frameworkFriendlyId: string,
  frameworkVersion: string,
  vulnerabilities: IVulnerability[],
}

export type ImplementationCount = {
  total: number,
  mitigated: number,
  target: number
};

export enum ExecutionStatus {
  Ok = 'ok',
  Failure = 'failure',
  Error = 'error'
}

export enum ImpactSeverity {
  None = 'none',
  High = 'high'
}

export interface IControlImpact {
  status: ExecutionStatus,
  controlFriendlyId: string,
  impact: ImpactSeverity,
  details: string
}

export interface IControlConfigureResult {
  status: ExecutionStatus,
  controlFriendlyId: string,
  details: string
}

export interface IVulnerabilityTrendDetails {
  sourceModuleId: number,
  securityLevel:SecurityLevel,
  countStatusUnknown: number,
  countStatusOpen: number,
  countStatusMitigated: number,
  countSeverityVeryLow: number,
  countSeverityLow: number,
  countSeverityMedium: number,
  countSeverityHigh: number
  countSeverityVeryHigh: number
}

export interface IVulnerabilityTrend {
  id: number,
  customerId: string,
  year: number,
  month: number,
  day: number,
  details: IVulnerabilityTrendDetails[],
}

export interface IFriendlyIdNameDescriptionDto
{
    friendlyId: string,
    name: string,
    shortName: string|undefined,
    description: string|undefined
}

export interface ITopicAndFunctionDto extends IFriendlyIdNameDescriptionDto
{
    function: IFriendlyIdNameDescriptionDto,
}
