import { StoreApi, create } from 'zustand';
import {
  ColumnFilter, ColumnFiltersState, OnChangeFn, PaginationState, VisibilityState,
} from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { IStringDictionary } from '../../types/Types';
import { TableColumnDefV8 } from './ReactTableV8';
import { ValidPageSizes } from './PaginationV8';
import { ISorting } from './PagedResultFilter';

export type TableStateV8 = {
  filters: ColumnFiltersState,
  setFilters: OnChangeFn<ColumnFiltersState>,
  getFilterValues: <TValue, >(filterId:string) => TValue[]|undefined,
  addOrUpdateFilterValues:(values:ColumnFilter[]) => void,
  columnVisibility: VisibilityState,
  setColumnVisibility: OnChangeFn<VisibilityState>,
  globalFilter: string,
  setGlobalFilter: (value: string) => void,
  pagination: PaginationState,
  setPagination: OnChangeFn<PaginationState>,
  sorting: ISorting[]
  setSorting: OnChangeFn<ISorting[]>,
};

const storeMap = {} as IStringDictionary<StoreApi<TableStateV8>>;

const TABLESTORE_LOCALSTORAGE_ID = 'tableState';

interface IDefaultState {
  defaultVisibilityState?: VisibilityState,
  defaultPaginationState?: PaginationState
}

interface TableStoreV8Props extends IDefaultState {
  id:string,
  initialFilters?:ColumnFiltersState
  initialSorting?:ISorting[]
}

const getTableStoreLocaleStoreKey = (key:string, id:string) => `table_state_${key}_${id}`;

const getFromLocalStoreOrDefault = <T, >(key:string, id:string, defaultValue:T) => {
  const storageItemAsString = localStorage.getItem(
    getTableStoreLocaleStoreKey(key, id),
  );
  if (!storageItemAsString) {
    return defaultValue;
  }
  try {
    return JSON.parse(storageItemAsString) as T;
  } catch {
    return defaultValue;
  }
};

const setToLocalStorage = <T, >(key:string, id:string, value:T) => {
  localStorage.setItem(
    getTableStoreLocaleStoreKey(key, id),
    JSON.stringify(value),
  );
};

export const hasFilterValue = (value:unknown) => {
  if (value === null) {
    return false;
  }
  if (value instanceof Array) {
    return value && value.length > 0;
  }
  return !!value;
};

const columnVisibilityKey = 'column_visibility';
const paginationKey = 'pagination';

const createTableStoreV8 = (props: TableStoreV8Props) => {
  const {
    id,
    defaultVisibilityState,
    defaultPaginationState,
    initialFilters,
    initialSorting,
  } = props;

  return create<TableStateV8>((set, get) => ({
    filters: (initialFilters ?? []) as ColumnFiltersState,
    setFilters: (updaterOrValue) => {
      set((state) => {
        const storageKeys = Object.keys(localStorage)
          .filter((k) => k.endsWith(`_${TABLESTORE_LOCALSTORAGE_ID}_v8`));

        if (storageKeys.length) {
          storageKeys.forEach((k) => { localStorage.removeItem(k); });
        }
        let updatedFilters = [] as ColumnFiltersState;
        if (typeof updaterOrValue === 'function') {
          updatedFilters = updaterOrValue(state.filters);
        } else {
          updatedFilters = updaterOrValue;
        }
        return { filters: updatedFilters };
      });
    },
    addOrUpdateFilterValues: (values:ColumnFilter[]) => {
      set((state) => {
        const updatedFilters = [...state.filters];
        values.forEach(({ id: filterId, value }) => {
          const index = Object.values(updatedFilters).findIndex((f) => f.id === filterId);
          if (index >= 0) {
            if (value !== null && value !== undefined) {
              updatedFilters[index].value = value;
            } else {
              updatedFilters.splice(index, 1);
            }
          } else if (value !== null && value !== undefined) {
            updatedFilters.push({
              id: filterId,
              value,
            });
          }
        });
        return { filters: updatedFilters };
      });
    },
    getFilterValues: <TValue, >(filterId:string) => {
      const { filters } = get();
      const index = Object.values(filters).findIndex((f) => f.id === filterId);
      const value = index >= 0 ? filters[index].value as TValue[] : undefined;
      return value;
    },
    columnVisibility: getFromLocalStoreOrDefault<VisibilityState>(
      columnVisibilityKey,
      id,
      defaultVisibilityState ?? {},
    ),
    setColumnVisibility: (updaterOrValue) => {
      set((state) => {
        const temp = typeof updaterOrValue === 'function'
          ? updaterOrValue(state.columnVisibility)
          : updaterOrValue;
        setToLocalStorage(columnVisibilityKey, id, temp);
        return { columnVisibility: temp };
      });
    },
    globalFilter: '',
    setGlobalFilter: (value) => {
      set(() => ({ globalFilter: value }));
    },
    // Note: using '' as id to use same navigation settings for all tables
    pagination: {
      ...getFromLocalStoreOrDefault<PaginationState>(
        paginationKey,
        '',
        defaultPaginationState ?? {
          pageIndex: 0,
          pageSize: 15,
        },
      ),
      // Always start on page 0 when first navigating to a table
      pageIndex: 0,
    },
    setPagination: (updaterOrValue) => {
      set((state) => {
        const temp = typeof updaterOrValue === 'function'
          ? updaterOrValue(state.pagination)
          : updaterOrValue;
        // eslint-disable-next-line no-debugger
        setToLocalStorage(paginationKey, '', temp);
        return { pagination: temp };
      });
    },
    sorting: initialSorting ?? [],
    setSorting: (updaterOrValue) => {
      set((state) => {
        const temp = typeof updaterOrValue === 'function'
          ? updaterOrValue(state.sorting)
          : updaterOrValue;
        return { ...state, sorting: temp };
      });
    },
  }));
};

export const columnsToVisibilityState = <T, >(columns:TableColumnDefV8<T, never>[]) => (
  columns.reduce((prev, col) => ({ ...prev, [col.accessorKey ?? '']: !col.defaultHidden }), {})
);

export interface ITableDefaults {
  visibilityState?:VisibilityState,
  pageSize?:ValidPageSizes,
  initialFilters?:ColumnFiltersState,
  initialSorting?:ISorting[]
}

export const useTableStoreV8 = (
  id:string,
  defaults?:ITableDefaults,
) => {
  const location = useLocation();

  // Key on location key to use a store per unique route.
  // This allows us to remember filters etc. on back navigation, while starting fresh
  // when first navigating to a table.
  const key = `${location.key}::${id}`;

  if (!storeMap[key]) {
    storeMap[key] = createTableStoreV8({
      id,
      defaultVisibilityState: defaults?.visibilityState,
      defaultPaginationState: defaults?.pageSize ? { pageSize: defaults.pageSize, pageIndex: 0 } : undefined,
      initialFilters: defaults?.initialFilters,
      initialSorting: defaults?.initialSorting,
    });
  }
  return { uniqueKey: key, store: storeMap[key] };
};

export const useDefaultTableStoreV8 = (
  defaults?:ITableDefaults,
) => {
  const key = '<generic>';

  if (!storeMap[key]) {
    storeMap[key] = createTableStoreV8({
      id: key,
      defaultVisibilityState: defaults?.visibilityState,
      defaultPaginationState: defaults?.pageSize ? { pageSize: defaults.pageSize, pageIndex: 0 } : undefined,
      initialFilters: defaults?.initialFilters,
      initialSorting: defaults?.initialSorting,
    });
  }
  return { uniqueKey: key, store: storeMap[key] };
};
