import React from 'react';
import { Spinner } from 'react-bootstrap';
import { IAsset } from '../../types/AssetsTypes';
import { VulnerabilitiesTable } from '../vulnerabilities/VulnerabilitiesTable';
import { useApi } from '../../query/GenericQuery';
import { Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { IVulnerability, VulnerabilityStatus } from '../vulnerabilities/Types';
import { PagedResult } from '../../types/PagedResult';
import { IVulnerabilityListOptions } from '../../types/Types';

export const AssetVulnerabilitiesTab = ({
  asset,
}:{
  asset: IAsset
}) => {
  const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
    'asset-vulnerabilities',
    {
      assets: [asset?.id],
    },
    {
      status: [VulnerabilityStatus.Open],
      assets: [asset?.id],
    },
    [
      { property: 'severity', direction: Direction.desc },
    ],
  );

  const { data: pagedVulnerabilities } = useApi<PagedResult<IVulnerability>>(
    'vulnerabilities',
    {
      ...pagedTableFilter.pageableQuery,
      assets: [asset?.id],
    },
  );

  return pagedVulnerabilities
    ? (
      <VulnerabilitiesTable
        isPaged
        id="asset-vulnerabilities-table"
        pagedResult={pagedVulnerabilities}
        pagedTableFilter={pagedTableFilter}
        hide={{ asset: true }}
        show={{ severity: true }}
      />
    ) : <Spinner animation="border" />;
};
