import {
  IApplicationInsights, ApplicationInsights, SeverityLevel,
} from '@microsoft/applicationinsights-web';
import React, { createContext, useContext, useMemo } from 'react';
import { IConfig } from './ConfigContext';

const AppInsightsContext = createContext<IApplicationInsights|undefined>(undefined);

const createAppInsights = (connectionString:string) => {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
    },
  });
  appInsights.loadAppInsights();
  return appInsights;
};

export const useAppInsights = () => {
  const context = useContext(AppInsightsContext);
  if (!context) {
    // If application insights is not enabled, return a dummy instance that
    // ignores any of the Application Insights methods we depend on
    return {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      trackPageView: () => {},
    } as unknown as IApplicationInsights;
  }
  return context;
};

export const ApplicationInsightsProvider = (
  { config, children }: { config: IConfig, children: React.ReactNode },
) => {
  const appInsights = useMemo(() => (config.APPLICATION_INSIGHTS_CONNECTION_STRING?.length
    ? createAppInsights(config.APPLICATION_INSIGHTS_CONNECTION_STRING)
    : undefined), [config]);

  if (appInsights) {
    // Ref. https://stackoverflow.com/a/65499554/738002
    // Create custom console implementation for logging to Application Insights
    const console = (function applicationInsightsLog(oldConsole) {
      if ((oldConsole as unknown as {aiLogger:boolean}).aiLogger) {
        return oldConsole;
      }

      return {
        aiLogger: true,
        ...oldConsole,
        debug(...args:string[]) {
          oldConsole.debug(...args);
          appInsights.trackTrace({
            message: (args?.length ? args[0] : ''),
            severityLevel: SeverityLevel.Verbose,
          });
        },
        log(...args:string[]) {
          oldConsole.log(...args);
          appInsights.trackTrace({
            message: (args?.length ? args[0] : ''),
            severityLevel: SeverityLevel.Information,
          });
        },
        warn(...args:string[]) {
          oldConsole.warn(...args);
          appInsights.trackTrace({
            message: (args?.length ? args[0] : ''),
            severityLevel: SeverityLevel.Warning,
          });
        },
        error(...args:string[]) {
          oldConsole.error(...args);
          appInsights.trackTrace({
            message: (args?.length ? args[0] : ''),
            severityLevel: SeverityLevel.Error,
          });
        },
      };
    }(window.console));

    // Override the build in window.console with Application Insights enabled version
    window.console = console;
  }

  return (
    <AppInsightsContext.Provider value={appInsights}>
      {children}
    </AppInsightsContext.Provider>
  );
};
