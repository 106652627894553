import React, { useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import {
  Card, Col, Row,
  Spinner,
} from 'react-bootstrap';
import { QueryClient } from '@tanstack/react-query';
import { IVulnerabilityListOptions } from '../../types/Types';
import {
  IVulnerability, SecurityLevel, Severity, VulnerabilityStatus,
} from './Types';
import VulnerabilityFilter, { IVulnerabilityFilter, QueryUtil } from './VulnerabilityFilter';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { VulnerabilitiesTable } from './VulnerabilitiesTable';
import { Module } from '../../types/AccessTypes';
import { IAccountDetails, useAccount } from '../../providers/AccountProvider';
import { getOrFetchFromApi, useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { TableStateV8 } from '../../common/table/TableStoreV8';
import { convertSortingToSortBy, Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { ValidPageSizes } from '../../common/table/PaginationV8';
import { CriticalityBar } from './CriticalityBar';

interface ILoaderData {
  options: IVulnerabilityListOptions|undefined,
  matrixSummary: Record<Severity, Record<Severity, number>>
}

const defaultSort = [
  { property: 'relativeSeverity', direction: Direction.desc },
];

export const vulnerabilitiesTableId = 'vulnerabilities-table';

export const VulnerabilitiesPage:IComponentWithLoader<
  ILoaderData,
  IVulnerabilityListOptions
> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    args?:IVulnerabilityListOptions|undefined,
  ) => {
    const initialOptions:IVulnerabilityListOptions&{sortBy?: string|undefined} = {
      ...args ?? {},
      pageSize,
      page: args?.page ?? 1,
      status: args?.status ?? [VulnerabilityStatus.Open],
      sortBy: convertSortingToSortBy(defaultSort) ?? undefined,
    };

    return {
      matrixSummary: await getOrFetchFromApi<Record<Severity, Record<Severity, number>>>(
        queryClient,
        'vulnerabilities/severityMatrixSummary',
      ),
      options: initialOptions,
    };
  },
  Component: () => {
    const [tableState, setTableState] = useState<TableStateV8>();
    const { getCustomerSetting } = useAccount();

    const [searchParams, setSearchParams] = useSearchParams();

    const {
      options,
    } = useLoaderData() as Awaited<ILoaderData>;

    const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
      'vulnerabilities-page',
      {},
      options ?? {},
      defaultSort,
    );

    const { pageableQuery } = pagedTableFilter;

    const {
      data: pagedVulnerabilities,
      isLoading: vulnerabilitiesLoading,
    } = useApi<PagedResult<IVulnerability>>(
      'vulnerabilities',
      pageableQuery,
    );

    const { data: summary } = useApi<{severityCount:Record<Severity, number>}>(
      'vulnerabilities/summary',
    );

    const customerSecurityLevel = getCustomerSetting(Module.none, 'security-level', SecurityLevel.Unknown);

    const { filters: tableFilters, addOrUpdateFilterValues } = tableState ?? {};

    const queryUtil = new QueryUtil<IVulnerabilityFilter>(
      customerSecurityLevel,
      (query) => {
        if (tableFilters && addOrUpdateFilterValues) {
          const filters = query.getFilters();
          const filterKeys = tableFilters.map((f) => f.id);
          const updatedFilters = Object.keys(filters)
            .filter((key) => filterKeys.includes(key))
            .map((key) => ({
              id: key,
              value: [filters[key]],
            }));
          addOrUpdateFilterValues(updatedFilters);
        }
      },
      [searchParams, setSearchParams],
    );

    return (
      <>
        <Row>
          <Col md={12}>
            <VulnerabilityFilter
              customerSecurityLevel={customerSecurityLevel}
              queryUtil={queryUtil}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                Vulnerabilities by criticality
              </Card.Header>
              <Card.Body>
                { summary?.severityCount
                  ? (
                    <CriticalityBar
                      criticalitySummary={summary.severityCount}
                      isSelected={(s) => (
                        !!(pageableQuery.relativeSeverity && pageableQuery.relativeSeverity.includes(s))
                      )}
                      onClick={(s) => {
                        pagedTableFilter.appendQuery({
                          ...pageableQuery,
                          impact: undefined,
                          probability: undefined,
                          severity: undefined,
                          relativeSeverity: pageableQuery.relativeSeverity?.includes(s)
                            ? pageableQuery.relativeSeverity.filter((p) => p !== s)
                            : [s],
                        });
                      }}
                    />
                  )
                  : null }
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <Card.Header>
                Vulnerabilities
              </Card.Header>
              <Card.Body className="overflow-auto">
                <Row>
                  <Col md={12}>
                    { !pagedVulnerabilities || vulnerabilitiesLoading
                      ? <Spinner animation="border" />
                      : (
                        <VulnerabilitiesTable
                          isPaged
                          id={vulnerabilitiesTableId}
                          emptyText={pagedTableFilter ? undefined : 'There are no vulnerabilities registered for your company'}
                          onInitialized={setTableState}
                          pagedResult={pagedVulnerabilities}
                          queryOptions={options}
                          pagedTableFilter={pagedTableFilter}
                          isLoading={vulnerabilitiesLoading}
                        />
                      )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  },
};

export default VulnerabilitiesPage;
