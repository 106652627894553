import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import { useConfigContext } from '../contexts/ConfigContext';

const getFilenameOrDefault = (
  contentDisposition:string|undefined,
  defaultFilename:string,
) : string => {
  if (!contentDisposition) {
    return defaultFilename;
  }
  if (contentDisposition.indexOf('attachment') >= 0) {
    const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
    if (!matches) return defaultFilename;
    return matches[1].replace(/["']/g, '');
  }
  return defaultFilename;
};

export const useDownloadFromApi = () => {
  const config = useConfigContext();

  return (apiSubPath: string, defaultFilename?:string) => {
    const toastId = 'api-download';
    toast.info('Preparing download', {
      toastId,
      autoClose: false,
    });

    const url = `${config.AZURE_REDIRECT_URI}/api/v1/${apiSubPath}`;
    // Solution ref. https://stackoverflow.com/a/53230807/738002
    axios({
      url,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const filename = getFilenameOrDefault(
        response.headers['content-disposition'],
        defaultFilename ?? 'file.bin',
      );

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename); // or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
      .catch((err) => {
        const errorMessage = err instanceof AxiosError
          ? `Download failed (status: ${err.response?.status} ${err.response?.statusText})`
          : 'Download failed';
        toast.error(errorMessage);
      })
      .finally(() => {
        try {
          toast.dismiss(toastId);
        } catch {
        // Ignore
        }
      });
  };
};
