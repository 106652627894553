import React from 'react';
import { Formik } from 'formik';
import {
  Alert,
  Button, Col, Form, Row, Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAssetTypeAsText, useGetSignificanceAsText } from '../../utils/TranslationUtils';
import { IAsset } from '../../types/AssetsTypes';
import { Significance } from '../vulnerabilities/Types';
import { assetTypesAsString } from '../../types/Types';
import { useInvalidateQueries } from '../../query/GenericQuery';

export const AssetDetailTab = ({
  asset,
}:{
  asset: IAsset
}) => {
  const assetTypeAsText = useAssetTypeAsText();
  const significanceAsText = useGetSignificanceAsText();

  const invalidateVulnerabilities = useInvalidateQueries('vulnerabilities');

  const saveMutation = useMutation({
    mutationFn: async (data:IAsset) => axios.put(`/api/v1/assets/${encodeURIComponent(asset.id)}`, {
      ...data,
      significance: data.significance?.length ? data.significance : null,
    }),
    onSuccess: async () => {
      toast.success('Asset was saved', {
        toastId: 'asset-save',
        updateId: 'asset-save',
      });
      await invalidateVulnerabilities();
    },
  });

  return (
    <Formik
      initialValues={asset}
      onSubmit={async (values, helpers) => {
        await saveMutation.mutateAsync(values);
        helpers.resetForm({ values });
      }}
    >
      {({
        values, handleSubmit, handleChange, dirty, handleReset,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={8} className="mb-3">
              <div>
                <Form.Label>Name:</Form.Label>
                <Form.Control disabled value={values.name} />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <Form.Label>Significance:</Form.Label>
                <div>
                  <Form.Select
                    value={values.significance}
                    name="significance"
                    onChange={handleChange}
                  >
                    <option value="">
                      {`Default (${(values.systemSignificance !== Significance.Medium ? significanceAsText(values.systemSignificance) : 'Neutral')})`}
                    </option>
                    { asset.significance === Significance.VeryLow || asset.significance === Significance.VeryHigh
                      ? (
                        <option value={asset.significance}>
                          {significanceAsText(asset.significance)}
                          {' *'}
                        </option>
                      )
                      : null }
                    <option value={Significance.Low}>{significanceAsText(Significance.Low)}</option>
                    <option value={Significance.Medium}>Neutral</option>
                    <option value={Significance.High}>{significanceAsText(Significance.High)}</option>
                  </Form.Select>
                  { asset.significance === Significance.VeryLow || asset.significance === Significance.VeryHigh
                    ? (
                      <Alert variant="info" className="p-2 mt-1">
                        Asset is using a custom significance which cannot be reset after being unselected.
                      </Alert>
                    ) : null }
                </div>
              </div>
            </Col>
            <Col md={8} className="mb-3">
              <div>
                <Form.Label>Unique ID:</Form.Label>
                <Form.Control disabled value={values?.friendlyId} />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <Form.Label>Type:</Form.Label>
                <Form.Select value={values?.type} disabled>
                  {assetTypesAsString.map((type) => (
                    <option key={type} value={type}>{assetTypeAsText(type, false)}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Stack direction="horizontal" gap={2}>
                <Button type="submit" disabled={!dirty}>Save</Button>
                <Button type="reset" variant="secondary" disabled={!dirty} onClick={handleReset}>Undo</Button>
              </Stack>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
