import React from 'react';
import { Card } from 'react-bootstrap';
import { VulnerabilityTrendChart } from '../pages/vulnerabilities/VulnerabilityTrendChart';
import { IVulnerabilityTrend, SecurityLevel, Severity } from '../pages/vulnerabilities/Types';
import { ITrendOptions } from '../pages/vulnerabilities/loader';
import { useApi } from '../query/GenericQuery';
import { VulnerabilityScoreTile } from '../pages/dashboard/VulnerabilityScoreTile';
import { TopVulnerabilitiesTable } from '../pages/vulnerabilities/TopVulnerabilitiesTable';
import { Direction } from '../common/table/PagedResultFilter';
import { useAccount } from '../providers/AccountProvider';
import { Module } from '../types/AccessTypes';

export const ReportVulnerabilities = () => {
  const { getCustomerSetting } = useAccount();
  const customerSecurityLevel = getCustomerSetting(Module.none, 'security-level', SecurityLevel.Advanced);

  const {
    data,
  } = useApi<IVulnerabilityTrend[], ITrendOptions>(
    'vulnerabilityTrend',
    {
      granularity: 'logarithmic',
      maxItems: 100,
    },
  );

  return (
    <div className="report">
      <div className="p-0 mb-3">
        <VulnerabilityScoreTile />
      </div>
      { data
        ? (
          <Card style={{ height: '400px' }}>
            <Card.Header>Vulnerability trend</Card.Header>
            <Card.Body>
              <VulnerabilityTrendChart
                animations={false}
                trendItems={data}
                securityLevel={customerSecurityLevel}
                height="350px"
              />
            </Card.Body>
          </Card>
        )
        : null}
      <Card>
        <Card.Header>Critical vulnerabilities</Card.Header>
        <Card.Body>
          <TopVulnerabilitiesTable
            relativeSeverity={[Severity.High, Severity.VeryHigh]}
            sorting={[
              { property: 'relativeSeverity', direction: Direction.asc },
              { property: 'created', direction: Direction.asc },
            ]}
          />
        </Card.Body>
      </Card>
    </div>
  );
};
