import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { createAlgizNoAccountRouter, routerFutures } from './Router';

export const AuthenticatedNoAccountApp = () => {
  const router = createAlgizNoAccountRouter();

  return (
    <RouterProvider
      router={router}
      future={routerFutures}
    />
  );
};
